import { FC } from 'react';
import ivclass from '@invideoio/web-shared/Modules/IVClass';
import ReactSlider from 'react-slider';
import { openLiveChat } from '@utils/common.utils';

interface Props {
  value: number;
  onChange: Function;
}

const FakeTrack: FC = () => (
  <div className="iv-absolute iv-top-[3px] iv-bottom-[3px] iv-left-[3px] iv-w-[50px] iv-bg-[#242424] iv-rounded-[80px]" />
);

const UserSeats: FC<Props> = ({ value, onChange }) => {
  return (
    <div>
      <div
        className={ivclass(
          'iv-flex iv-items-center',
          'iv-rounded-[80px]',
          'iv-h-[38px]',
          'iv-py-3',
          'iv-pr-3',
          'iv-pl-25',
          'iv-bg-black-7',
          'iv-relative',
        )}
      >
        <FakeTrack />
        <ReactSlider
          className="user-seats"
          thumbClassName="seat-thumb iv-cursor-pointer"
          thumbActiveClassName="iv-border-0"
          min={1}
          max={10}
          value={value}
          renderThumb={(props, state) => (
            <div {...props}>{`${state.valueNow}x`}</div>
          )}
          renderTrack={(props, state) => {
            if (state.index === 0) {
              return (
                <div
                  {...props}
                  className={ivclass(
                    'iv-bg-[#242424]',
                    'iv-h-full',
                    'iv-rounded-[80px]',
                  )}
                />
              );
            } else {
              return <div {...props} />;
            }
          }}
          onChange={it => onChange(it)}
        />
      </div>
      <div
        className={ivclass('iv-text-body-s iv-text-black-50 iv-text-center')}
      >
        More than 10 users?{' '}
        <span
          className="iv-text-blue iv-cursor-pointer"
          onClick={() => openLiveChat()}
        >
          Contact us
        </span>
      </div>
    </div>
  );
};

export default UserSeats;
