import IVButton from '@invideoio/uniformd/components/Button/index';
import ivclass from '@invideoio/web-shared/Modules/IVClass';
import { PlatformType } from '@invideoio/web-shared/constants/common';
import { DispatchContext, StateContext } from '@store/store';
import { useContext, useMemo } from 'react';
import { PricingDuration, PricingPlanTopProps } from 'src/types/pricing.types';
import Badge from './Badge';
import BillingText from './BilledText';
import DisplayPrice from './DisplayPrice';
import UserSeats from './UserSeats';

const PricingPlanTop = (props: PricingPlanTopProps) => {
  const {
    planName,
    planKey,
    planDescription,
    bannerText,
    planPrice,
    CTA,
    isYearlyDuration,
    theme = 'light',
    onClick,
    disabled = false,
    classNames = '',
  } = props;
  const dispatch = useContext(DispatchContext);
  const globalState = useContext(StateContext);
  const { seats } = globalState;

  const billingCycle = useMemo(
    () => (isYearlyDuration ? PricingDuration.YEARLY : PricingDuration.MONTHLY),
    [isYearlyDuration],
  );

  const setSeats = (value: number) => {
    dispatch({ type: 'SET_SEATS', payload: { [planKey]: value } });
  };

  return (
    <div
      className={ivclass(
        'iv-relative',
        'iv-overflow-hidden',
        { 'iv-bg-grey-90': theme === 'dark' },
        { 'iv-bg-grey-0': theme === 'light' },
        classNames,
      )}
    >
      {bannerText && <Badge text={bannerText} />}
      <div>
        <div
          className={ivclass(
            'iv-pb-[12px] sm:iv-pb-[0px]',
            'iv-flex iv-justify-between',
          )}
        >
          <span
            className={ivclass(
              { 'iv-text-grey-0': theme === 'dark' },
              { 'iv-text-black-85': theme === 'light' },
              'iv-text-heading-s sm:iv-text-[30px] iv-leading-[36px]',
              'iv-font-extrabold',
            )}
          >
            {planName}
          </span>
          <div className={ivclass('sm:iv-block iv-hidden')}>
            <DisplayPrice
              theme={theme}
              planPrice={planPrice[billingCycle]}
              seats={seats[planKey]}
            />
          </div>
        </div>

        <div
          className={ivclass(
            'lg:iv-pr-5',
            { 'iv-text-white-50': theme === 'dark' },
            { 'iv-text-grey-50': theme === 'light' },
            'iv-text-body-3xl iv-font-medium sm:iv-text-body-xl',
          )}
          title={planDescription[PlatformType.Desktop]}
        >
          {globalState.platform === PlatformType.Mobile
            ? planDescription.mobile
            : planDescription.desktop}
        </div>

        <div className={ivclass('iv-py-15 sm:iv-hidden')}>
          <DisplayPrice
            theme={theme}
            planPrice={planPrice[billingCycle]}
            seats={seats[planKey]}
          />
        </div>

        {planKey === 'free' ? (
          <div className={ivclass('iv-py-[47px] sm:iv-py-[0px]')}></div>
        ) : (
          <div className={ivclass('iv-py-20 sm:iv-pb-[0px]')}>
            <div
              className={ivclass(
                'iv-text-center iv-text-body-xs',
                'iv-h-[16px]',
                'sm:iv-block iv-hidden',
              )}
            >
              <BillingText
                billingCycle={billingCycle}
                planPrice={planPrice[billingCycle]}
                seats={seats[planKey]}
              />
            </div>
            <UserSeats onChange={setSeats} value={seats[planKey]} />
          </div>
        )}
        <IVButton
          className={ivclass(
            'iv-inline-flex iv-justify-center iv-items-center',
            'iv-cursor-pointer',
            'iv-rounded-full',
            'iv-px-15',
            'iv-h-52',
            'iv-w-full',
            'xl:iv-text-body-3xl lg:iv-text-body-3xl',
            'sm:iv-hidden',
            'iv-font-semibold',
            CTA.backgroundColorClass,
            {
              'iv-border iv-border-solid':
                CTA.backgroundColorClass === 'iv-bg-white-10' &&
                theme === 'light',
            },
            'iv-text-grey-0',
            disabled && 'iv-cursor-not-allowed',
          )}
          onClick={onClick}
          disabled={disabled}
        >
          {CTA.text}
        </IVButton>

        <div
          className={ivclass(
            'iv-text-body-m',
            'iv-mt-5',
            'iv-h-[20px] sm:iv-hidden',
          )}
        >
          <BillingText
            billingCycle={billingCycle}
            planPrice={planPrice[billingCycle]}
            seats={seats[planKey]}
          />
        </div>
      </div>
    </div>
  );
};

export default PricingPlanTop;
