import ivclass from '@invideoio/web-shared/Modules/IVClass';
import React from 'react';

interface DisplayPriceProps {
  planPrice: {
    currency: string;
    price: number;
    subText?: string;
  };
  theme: 'light' | 'dark';
  seats?: number;
}

const DisplayPrice = (props: DisplayPriceProps) => {
  const { currency, price, subText = '' } = props.planPrice;
  const { theme = 'light', seats = 1 } = props;

  return (
    <React.Fragment>
      <div
        className={ivclass(
          'iv-inline-block',
          { 'iv-text-grey-0': theme === 'dark' },
          'iv-text-[46px] iv-leading-[50px]',
          'sm:iv-text-[30px] iv-leading-[36px]',
          'iv-font-extrabold',
        )}
      >
        {currency}
        {price * seats || '0'}
      </div>

      <div
        className={ivclass(
          'iv-inline-block',
          'xl:iv-pl-6 lg:iv-pl-4 iv-pl-7',
          { 'iv-text-black-50': theme === 'dark' },
          { 'iv-text-black-50': theme === 'light' },
          'iv-text-body-3xl',
          'iv-font-semibold',
        )}
      >
        /mo*
      </div>
    </React.Fragment>
  );
};

export default DisplayPrice;
