import ivclass from 'clsx';
import FeatureList from './FeaturesList';

import IVButton from '@invideoio/web-shared/components/Button';
import { PricingPlanBottomProps } from 'src/types/pricing.types';

const PricingPlanBottom = (props: PricingPlanBottomProps) => {
  const { features, CTA, theme = 'light', onClick, planKey } = props;

  return (
    <div
      className={ivclass(
        'iv-bg-grey-7',
        'iv-flex sm:iv-block',
        'xl:iv-flex-col lg:iv-flex-col',
        'md:iv-flex-wrap',
        'iv-py-[14px] iv-px-[28px]',
        'iv-flex-grow',
      )}
    >
      <FeatureList featuresList={features} planKey={planKey} />
      <IVButton
        className={ivclass(
          'iv-inline-flex iv-justify-center iv-items-center',
          'iv-cursor-pointer',
          'iv-rounded-full',
          'iv-px-15',
          'iv-h-52',
          'iv-w-full',
          'iv-text-body-xl iv-font-semibold',
          'sm:iv-block iv-hidden',
          CTA.backgroundColorClass,
          {
            'iv-border iv-border-solid':
              CTA.backgroundColorClass === 'iv-bg-white-10' &&
              theme === 'light',
          },
          'iv-text-grey-0',
        )}
        onClick={onClick}
      >
        {CTA.text}
      </IVButton>
    </div>
  );
};

export default PricingPlanBottom;
